import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  SubPage,
  Text,
  Title,
  SubTitle,
  LightSubTitle,
  TableView,
  Image,
  MapContainer,
  Icon,
  useCustomerCoords,
  DriverInfoPanel,
  useSndDriverAddress,
} from '@adac/core-view';
import {
  ChildrenProps,
  __,
  getClaimReference,
} from '@adac/core-model';

import StoresContext from '../../stores';
import { useStatusListeners } from '../../hooks/useStatusListeners';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const {
  REACT_APP_DUMMY_SND_MOVING: dummy,
  REACT_APP_DUMMY_SND_SLOWING: slowing,
} = process.env;

const CustomSubtitle = styled(SubTitle)`
  font-weight: normal;
  font-family:  ${props => props.theme.font.face.office};
  margin-top: 10px;
`;

const OrderViewStyles = styled.div`
  width: 16px;
  height: 16px;

  border-radius: 8px;

  text-align: center;
  display: grid;
  place-items: center;

  background-color: ${props => props.theme.colors.backgroundDark};
`;

const OrderText = styled(Text)`
  color: ${props => props.theme.colors.background};
  font-weight: bold;
  font-family: ${props => props.theme.font.face.bold.default};
  font-size: 12px;
`;

const OrderView = ({ index }: { index: number }) => (
  <OrderViewStyles>
    <OrderText>{index}</OrderText>
  </OrderViewStyles>
);


const dummyOptions = (/true/i).test(dummy || '')
  ? {
    slowing: slowing
      ? +slowing
      : undefined,
  }
  : undefined;

const useCaseDetails = () => {
  const { case: caseStore } = useContext(StoresContext);

  useStatusListeners(caseStore);

  const customerCoords = useCustomerCoords({ caseStore });
  const driverCoords = useSndDriverAddress({
    caseStore,
    dummyOptions,
    customerCoords,
  });
  const driverInfo = caseStore.driverInfo || {};
  return {
    driverInfo,
    driverCoords,
    customerCoords,
    commissioningReference: getClaimReference(caseStore.commissioningReference),
  };
};

interface PageTitleProps {
  title: string;
  commissioningReference: string;
}


const PageTitle = observer(({ title, commissioningReference }: PageTitleProps) => {
  const isInsurance = useIsInsuranceProduct();

  return (
    <>
      <Title>
        {title}
      </Title>
      {!isInsurance && <LightSubTitle>{__('Hermes Id')} {commissioningReference} </LightSubTitle>}
    </>
  );
});

export const DriverArrived = observer(() => {
  const { driverInfo, commissioningReference } = useCaseDetails();
  return (
    <>
      <SubPage>
        <PageTitle title={__('Ihr Helfer ist da!')} commissioningReference={commissioningReference} />
        <CustomSubtitle>{__('Ihr Helfer ist angekommen und wird gleich bei Ihnen sein.')}</CustomSubtitle>
      </SubPage>
      <DriverInfoPanel {...driverInfo} isOpen={false} />
    </>
  );
});

export const DriverApproaching = observer(() => {
  const {
    driverInfo, driverCoords, customerCoords, commissioningReference,
  } = useCaseDetails();
  return (
    <>
      <SubPage>
        <PageTitle title={__('Hilfe ist unterwegs')} commissioningReference={commissioningReference} />
        <CustomSubtitle>
          {__('Ihr Helfer ist verständigt und befindet sich auf dem Weg zu Ihnen. Er wird sich in wenigen Minuten mit Ihnen in Verbindung setzen und alle Details mit Ihnen abstimmen!')}
          <br />
        </CustomSubtitle>
        <CustomSubtitle>
          {__('Bitte stellen Sie sicher, dass Sie telefonisch erreichbar sind.')}
        </CustomSubtitle>
      </SubPage>
      <Image source="/assets/images/static/mobile/adac-waiting-anim-only-looped.gif" fullWidth />

      <DriverInfoPanel {...driverInfo} />

      <MapContainer
        style={{
          top: '80px',
        }}
        customerPosition={customerCoords}
        driverPosition={driverCoords}
      />
    </>
  );
});

export default observer(({ children }: ChildrenProps) => {
  const isInsurance = useIsInsuranceProduct();

  const allCellItems = [
    {
      key: __('Ihre Identität muss bestätigt werden'),
      title: __('Ihre Identität muss bestätigt werden'),
      description:
          __('Um Missbrauch vorzubeugen, müssen wir sicherstellen, dass es sich tatsächlich um Ihre Wohnung handelt. Deshalb bitten wir Sie, Ihre Ausweispapiere spätestens nach der Öffnung vorzuzeigen.'),
    },
    {
      title: __('Bestehende Schäden werden dokumentiert'),
      key: __('Bestehende Schäden werden dokumentiert'),
      description:
          __('Transparenz ist unser oberstes Gebot. Falls die Tür bereits Beschädigungen aufweist, werden wir diese fotografieren und dokumentieren.'),
    },
    {
      title: __('Der Helfer öffnet Ihre Tür'),
      key: __('Der Helfer öffnet Ihre Tür'),
      description: (
        <>
          <Text>
            {__('Wie lange es dauern wird um Ihre Tür zu öffnen, ist situationsabhängig und kann erst')}
            {' '}
            <strong>{__('vor Ort durch den Helfer eingeschätzt')}</strong>
            {' '}
            {__('werden.')}
          </Text>
          <Text>
            {__('In nahezu allen Fällen öffnen wir die Türen ohne Beschädigung von Tür, Rahmen oder Schloss. In Einzelfällen muss das Schloss aufgebohrt werden. Im Falle einer unausweichlichen Beschädigung werden wir Sie ausdrücklich darauf hinweisen und')}
            {' '}
            <strong>{__('erst nach Ihrer Zustimmung')}</strong>
            {' '}
            {__('weiter verfahren.')}
          </Text>
        </>
      ),
    },
  ];

  const nonInsuranceCellItems = [
    {
      title: __('Nach Abschluss der Leistung erhalten Sie Ihre Rechnung per Post'),
      key: __('Nach Abschluss der Leistung erhalten Sie Ihre Rechnung aufs Handy'),
      description: __('Ihre Rechnung erhalten Sie wenige Tage nach der Türöffnung auf dem Postweg.'),
    },
    {
      title: __('Welche Zusatzkosten können bei defekter Mechanik enstehen?'),
      key: __('Welche Zusatzkosten können bei defekter Mechanik enstehen?'),
      description: (
        <Text>{__('Sollte die Mechanik Ihrer Tür defekt sein, wird der Schlüsselnotdienst Ihnen vor Ort die zusätzlichen Kosten bzw. Maßnahmen erläutern.')}
          {' '}
          <a href="https://www.adac.de/services/schluesseldienst/" target="pricing"><strong>{__('hier')}</strong></a>
          {' '}
          {__('finden Sie bereits vorab eine Übersicht der Kosten.')}
        </Text>
      ),
    },
  ];

  const cellItems = [
    ...allCellItems,
    ...(isInsurance ? [] : nonInsuranceCellItems),
  ];

  return (
    <>
      {children}
      <SubPage>
        <SubTitle big>{__('So geht es nach der Ankunft weiter')}:</SubTitle>
        <TableView
          cellItems={cellItems}
          rightView={<Icon name="down" />}
          rotateRightViewOnOpenClose
          orderView={(index: number) => <OrderView index={index + 1} />}
        />
      </SubPage>
    </>
  );
});
