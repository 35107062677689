"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPasswordCommit = exports.resetPasswordStart = void 0;
const core_model_1 = require("@adac/core-model");
const common_1 = require("./common");
const resetPasswordStart = (email) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield (0, common_1.request)((0, core_model_1.getApiRoutes)().auth.mfa.passwordResetStart, {
            method: 'POST',
            data: { email },
        });
        if (data.tokenKey)
            return data.tokenKey;
        throw new Error('No tokenKey returned');
    }
    catch (err) {
        throw new Error(err);
    }
});
exports.resetPasswordStart = resetPasswordStart;
const resetPasswordCommit = (newPassword, confirmPassword, tokenKey, tokenValue) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield (0, common_1.request)((0, core_model_1.getApiRoutes)().auth.users.resetPassword, {
            method: 'POST',
            data: {
                newPassword,
                confirmPassword,
                tokenKey,
                tokenValue,
            },
        });
    }
    catch (err) {
        throw new Error(err);
    }
});
exports.resetPasswordCommit = resetPasswordCommit;
