import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import {
  PageTransitionContainer, Page, StatusRender, findMatchingStatusComponent, matchStatus, useLocation, Spinner,
  StartPage,
} from '@adac/core-view';
import {
  keyGenerator, CaseStatusList, ChildrenProps, CaseActions, getCaseActionName,
} from '@adac/core-model';

import Progress from '../pages/Progress';
import DriverOnTheWay, { DriverApproaching, DriverArrived } from '../pages/DriverOnTheWay';
import Documents from '../pages/Documents';
// import DevelopmentHelperPages from '../pages/DeveloperHelperPages';
import StoresContext from '../../stores';
import { Landing } from '../pages/Landing';
// import CaseStatusRouter from '../pages/CaseStatusRouter';

interface RenderFirstValidStatusOnlyProps extends ChildrenProps {
   status: string;
   default: () => JSX.Element;
}
const RenderFirstValidStatusOnly = ({
  status, default: DefaultComponent, children,
}: RenderFirstValidStatusOnlyProps) => {
  const toBeRendered = React.Children.toArray(children).find(child => matchStatus((child as JSX.Element).props.onStatus, status));
  return <>{toBeRendered || <DefaultComponent />}</>;
};

interface RouterProps {
  afterElement?: JSX.Element;
}

export default observer(({ afterElement }: RouterProps): JSX.Element => {
  const stores = useContext(StoresContext);
  const location = useLocation();
  const hasToken = !!location.pathname.match(/^\/\w+/g);

  const landingContent = hasToken ? <Spinner /> : undefined;

  const routes: JSX.Element[] = [
    <StatusRender
      status={stores.case.status}
      onStatus={[
        CaseStatusList.START,
      ]}
      render={(<StartPage />)}
    />,
    <StatusRender
      status={stores.case.status}
      onStatus={[
        CaseStatusList.SND_SELECTED,
        CaseStatusList.DRIVER_SELECTED,
      ]}
      component={Progress}
    />,
    <StatusRender
      status={stores.case.status}
      onStatus={[
        CaseStatusList.COMMISSION_CONFIRMED,
        CaseStatusList.DRIVER_APPROACHING,
      ]}
      component={() => <DriverOnTheWay><DriverApproaching /></DriverOnTheWay>}
    />,
    <StatusRender
      status={stores.case.status}
      onStatus={[
        CaseStatusList.DRIVER_ARRIVED,
        CaseStatusList.OPENING_STARTED,
        CaseStatusList.EDITING_EXTRA_DAMAGE,
        CaseStatusList.EXTRA_DAMAGE_ONGOING,
      ]}
      component={() => <DriverOnTheWay><DriverArrived /></DriverOnTheWay>}
    />,
    <StatusRender
      status={stores.case.status}
      onStatus={[
        getCaseActionName(CaseActions.SEND_SURVEY),
        CaseStatusList.OPENING_COMPLETED,
        CaseStatusList.CASE_COMPLETED,
      ]}
      component={() => <Documents />}
    />,

    // <StatusRender path="/:case_token" component={CaseStatusRouter} />,

    // <Route pageTransition="slideUp" path="/dev" component={DevelopmentHelperPages} />,
  ];

  // TODO: enable pagetransitions again
  const foundMatchingRoute = findMatchingStatusComponent(routes, stores.case.status);
  // const foundMatchingRoute = findMatchingRoute({ status: stores.case.status, routes });
  if (foundMatchingRoute && foundMatchingRoute.props.pageTransition) {
    // NOTE: The reason I don't use pageTranstion just a local reference, using the stores we might be able to have more control, and manage from other place
    stores.ui.setPageTransition(foundMatchingRoute && foundMatchingRoute.props.pageTransition);
  } else {
    stores.ui.setPageTransition();
  }

  return (
    <PageTransitionContainer
      pageTransition={stores.ui.pageTransition}
      pageTransitionDelay={stores.ui.pageTransitionDelay}
      routes={routes}
      transitionKey={stores.case.status}
      ContentStyle={Page}
    >

      <RenderFirstValidStatusOnly status={stores.case.status} default={() => <Landing>{landingContent}</Landing>}>{routes.map(RouteConfig => React.cloneElement(RouteConfig, { key: keyGenerator(RouteConfig.props, ['onStatus']) }))}</RenderFirstValidStatusOnly>
      {/* after elements */}
      <>{afterElement}</>
    </PageTransitionContainer>
  );
});
