"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCreateCustomError = void 0;
const core_model_1 = require("@adac/core-model");
const catchError_1 = require("../helpers/catchError");
function handleOurBackendError(error) {
    const errors = error.response.data;
    if (errors && errors.length >= 1) {
        const customError = errors[0].field
            ? new core_model_1.ValidationError(errors[0].field.error, errors[0].field.name, errors[0].field.limit, errors[0].field.zipcodeList)
            : new core_model_1.HTTPError(`${errors[0].message || errors[0].error}`, core_model_1.SERVER_ERROR, error.stack, error.response.status);
        (0, catchError_1.catchError)(error, customError, {
            requestFunc: 'Server responded, but not with 2xx, Server was our service',
            response: JSON.stringify(error.response),
        });
        return customError;
    }
    return null;
}
function handleExternalServiceError(error) {
    var _a, _b;
    const customError = new core_model_1.HTTPError(((_b = (_a = error.response.data) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) || JSON.stringify(error.response.data), core_model_1.SERVER_ERROR, error.stack, error.response.status);
    (0, catchError_1.catchError)(error, customError, {
        requestFunc: 'Server responded, but not with 2xx, Server was external service',
        response: JSON.stringify(error.response),
    });
    return customError;
}
function handleNoResponse(error, url) {
    console.log('handleNoResponse', error);
    const customError = new core_model_1.HTTPError('Server did not respond', core_model_1.SERVICE_UNAVAILABLE, error.stack, undefined, { url });
    (0, catchError_1.catchError)(error, customError, 'request: Server did not respond');
    return customError;
}
function handleClientError(error, url, method) {
    console.log('handleClientError', error);
    const customError = new core_model_1.HTTPError('Setting up request failed', core_model_1.SERVER_ERROR, error.stack, undefined, { url, method });
    (0, catchError_1.catchError)(error, customError, 'request: An error happened while setting up the request');
    return customError;
}
function defaultCreateCustomError(error, url, method) {
    let customError;
    if (error.response) {
        const ourBackendError = handleOurBackendError(error);
        if (ourBackendError) {
            customError = ourBackendError;
        }
        else {
            customError = handleExternalServiceError(error);
        }
    }
    else if (error.request) {
        customError = handleNoResponse(error, url);
    }
    else {
        customError = handleClientError(error, url, method);
    }
    return customError;
}
exports.defaultCreateCustomError = defaultCreateCustomError;
