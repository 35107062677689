"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiRoutes = exports.getCustomerRoutes = exports.getAdacLinks = void 0;
var _a = process.env, apiRootConfig = _a.REACT_APP_API_BASE_URL, socketRoot = _a.REACT_APP_SOCKET_BASE_URL, customerPortalConfig = _a.REACT_APP_CUSTOMER_PORTAL_URL;
var getAdacLinks = function () { return Object.freeze({
    dataprivacy: 'https://www.adac.de/datenschutz-dsgvo/online/',
    impressum: 'https://www.adac.de/impressum-ev/',
    cookie: 'https://www.adac.de/datenschutz-dsgvo/cookie-einstellungen/',
}); };
exports.getAdacLinks = getAdacLinks;
var getCustomerRoutes = function (validateConfig) {
    if (validateConfig === void 0) { validateConfig = (process.env.NODE_ENV !== 'test'); }
    if (validateConfig && !customerPortalConfig)
        throw new Error('REACT_APP_CUSTOMER_PORTAL_URL has to be defined!');
    var customerPortal = customerPortalConfig || '';
    return Object.freeze({
        staticAsset: {
            pdfRoot: "".concat(customerPortal, "/assets/pdf"),
        },
    });
};
exports.getCustomerRoutes = getCustomerRoutes;
var getApiRoutes = function (validateConfig, apiRoot) {
    if (validateConfig === void 0) { validateConfig = (process.env.NODE_ENV !== 'test'); }
    if (apiRoot === void 0) { apiRoot = apiRootConfig || ''; }
    if (validateConfig && !apiRoot)
        throw new Error("apiRoot has to be defined (apiRoot=".concat(apiRoot, ", REACT_APP_API_BASE_URL=").concat(apiRootConfig, ")!"));
    var pdfPaths = Object.freeze({
        commissionReport: function (action) { return "/comission/opening/".concat(action || ':action'); },
        commissionExtraDamage: function (action) { return "/comission/breakdoor/".concat(action || ':action'); },
        finalStatusReport: function (action, finalReportType) { return "/final/report/".concat(action || ':action').concat(finalReportType ? "/".concat(finalReportType) : ''); },
        comissionAdditional: function () { return '/'; },
    });
    return Object.freeze({
        paths: {
            pdf: pdfPaths,
            driver: {
                offerDispatch: function (action) { return "/offer/".concat(action || ':action'); },
                timesuggestionSaved: '/timesuggestion/saved',
                openingFailed: '/opening/failed',
            },
        },
        root: "".concat(apiRoot),
        socket: "".concat(socketRoot || apiRoot),
        healthCheck: '/api/health/check',
        zip: {
            zipCodes: "".concat(apiRoot, "/admin/zip-codes"),
            supportedCodes: "".concat(apiRoot, "/admin/zip-codes/supported-codes"),
            isSupported: function (zip) { return "".concat(apiRoot, "/admin/zip-codes/is-supported/").concat(zip); },
        },
        city: {
            cityNames: "".concat(apiRoot, "/admin/zip-codes/city-names"),
        },
        customer: {
            case: function (token) { return ({
                pdf: function (type, action, finalReportType) { return "".concat(apiRoot, "/").concat(token).concat(pdfPaths[type](action, finalReportType)); },
                status: "".concat(apiRoot, "/workflow/customer/status/").concat(token),
                action: function (action) { return "".concat(apiRoot, "/workflow/customer/action/").concat(token, "/").concat(action); },
            }); },
        },
        hubble: {
            post: "".concat(apiRoot, "/hubble/commissioning/homeassistance"),
        },
        admin: {
            settings: {
                notification: {
                    getSignInUserSetting: function (userId) { return "".concat(apiRoot, "/admin/settings/notification/").concat(userId); },
                    updateSignInUserSetting: function (userId) { return "".concat(apiRoot, "/admin/settings/notification/").concat(userId); },
                },
            },
        },
        backoffice: {
            escalation: {
                assistant: "".concat(apiRoot, "/admin/backoffice/assistant-escalation"),
                supervisor: "".concat(apiRoot, "/admin/backoffice/supervisor-escalation"),
                taken: "".concat(apiRoot, "/admin/backoffice/taken-cases"),
            },
            zipCode: function (zip) { return "".concat(apiRoot, "/admin/backoffice/zip-codes/").concat(zip); },
            holiday: {
                list: "".concat(apiRoot, "/admin/backoffice/holidays"),
                get: function (id) { return "".concat(apiRoot, "/admin/backoffice/holidays/").concat(id); },
            },
            region: {
                list: "".concat(apiRoot, "/admin/backoffice/region"),
                listActive: "".concat(apiRoot, "/admin/backoffice/region/listActive"),
            },
            users: {
                create: "".concat(apiRoot, "/admin/users/backoffice"),
                list: "".concat(apiRoot, "/admin/users/backoffice"),
                listBlocked: "".concat(apiRoot, "/admin/users/backoffice/blocked"),
                getMe: "".concat(apiRoot, "/admin/users/backoffice/me"),
                get: function (userId) { return "".concat(apiRoot, "/admin/users/backoffice/").concat(userId); },
            },
            adminLog: {
                getLatest: function (entity, userId) { return "".concat(apiRoot, "/admin/backoffice/adminlog/").concat(entity, "/").concat(userId); },
                getById: function (id) { return "".concat(apiRoot, "/admin/backoffice/adminlog/").concat(id); },
            },
            case: {
                setStatus: function (token, action) { return "".concat(apiRoot, "/admin/backoffice/case/").concat(token, "/").concat(action); },
                newCase: '/newcase',
                create: "".concat(apiRoot, "/admin/backoffice/commissioning"),
                basePrice: function (zip, date) { return "".concat(apiRoot, "/admin/cases/base-price/").concat(zip).concat(date ? "/".concat(date) : ''); },
                logs: function (id) { return "".concat(apiRoot, "/admin/backoffice/case/").concat(id, "/logs"); },
                comment: function (token) { return "".concat(apiRoot, "/admin/backoffice/").concat(token, "/create-case-comment"); },
                getCaseByToken: function (token) { return "".concat(apiRoot, "/admin/backoffice/").concat(token); },
                list: {
                    get: function (id) { return "".concat(apiRoot, "/admin/cases/").concat(id); },
                    closed: "".concat(apiRoot, "/admin/cases/closed"),
                    open: "".concat(apiRoot, "/admin/cases/open"),
                },
            },
        },
        statistics: {
            full: function (params) { return "".concat(apiRoot, "/admin/backoffice/fullreport").concat(params ? "/".concat(params.join('/')) : ''); },
        },
        auth: {
            authCode: function (authCode) { return "".concat(apiRoot, "/auth/").concat(authCode); },
            mfa: {
                passwordChangeStart: "".concat(apiRoot, "/auth/mfa/password-change/start"),
                passwordResetStart: "".concat(apiRoot, "/auth/mfa/password-reset/start"),
                confirm: "".concat(apiRoot, "/auth/mfa/confirm"),
            },
            loginToCompanyAdmin: "".concat(apiRoot, "/auth/login/company"),
            loginToBackoffice: "".concat(apiRoot, "/auth/login/backoffice"),
            users: {
                changePassword: "".concat(apiRoot, "/admin/users/change-password"),
                changePhone: "".concat(apiRoot, "/admin/users/change-phone"),
                resetPassword: "".concat(apiRoot, "/admin/users/reset-password"),
                getMe: "".concat(apiRoot, "/admin/users/me"),
                unblock: function (userId) { return "".concat(apiRoot, "/admin/users/unblock/").concat(userId); },
            },
        },
        driverAdmin: {
            list: "".concat(apiRoot, "/admin/snd-drivers"),
            create: "".concat(apiRoot, "/admin/snd-drivers"),
            get: function (driverId) { return "".concat(apiRoot, "/admin/snd-drivers/").concat(driverId); },
        },
        companyAdmin: {
            list: "".concat(apiRoot, "/admin/backoffice/snd-companies"),
            listActive: "".concat(apiRoot, "/admin/backoffice/snd-companies/active"),
            getById: function (id) { return "".concat(apiRoot, "/admin/backoffice/snd-companies/").concat(id); },
            getCaseByToken: function (token) { return "".concat(apiRoot, "/admin/snd-companies/case/").concat(token); },
            putById: function (id) { return "".concat(apiRoot, "/admin/backoffice/snd-companies/").concat(id); },
            editDriversList: function (companyId) { return encodeURI("/admin/backoffice/snd-companies/".concat(companyId, "/admin/backoffice/drivers")); },
            getNotificationSettings: function (companyId) { return "".concat(apiRoot, "/admin/backoffice/snd-companies/settings/notification/").concat(companyId); },
            saveNotificationSettings: function () { return "".concat(apiRoot, "/admin/backoffice/snd-companies/settings/notification"); },
        },
        partner: {
            getMyCompany: "".concat(apiRoot, "/admin/snd-companies/me"),
            notificationSettings: "".concat(apiRoot, "/admin/settings/notification"),
            caseList: {
                allCase: "".concat(apiRoot, "/admin/cases"),
                closed: "".concat(apiRoot, "/admin/cases/closed"),
                open: "".concat(apiRoot, "/admin/cases/open"),
            },
            case: function (token) { return ({
                offerDispatch: function (companyId) { return "".concat(apiRoot, "/").concat(token, "/").concat(companyId, "/offer/dispatch"); },
                status: function (companyId) { return "".concat(apiRoot, "/workflow/partner/status/").concat(token, "/").concat(companyId); },
                report: {
                    company: function (companyId) { return "".concat(apiRoot, "/workflow/partner/report/").concat(token, "/").concat(companyId); },
                    view: function (reportType, finalReportType) { return "".concat(apiRoot, "/workflow/partner/report/").concat(token, "/view/").concat(reportType).concat(finalReportType ? "/".concat(finalReportType) : ''); },
                },
                action: ({
                    callCustomer: function () { return "".concat(apiRoot, "/workflow/partner/callCustomer/").concat(token); },
                    setStatus: function (action, companyId) { return "".concat(apiRoot, "/workflow/partner/action/").concat(token, "/").concat(action, "/").concat(companyId); },
                    position: {
                        get: "".concat(apiRoot, "/workflow/partner/position/").concat(token),
                        getAll: "".concat(apiRoot, "/workflow/partner/position/").concat(token, "/all"),
                    },
                    timeSuggestion: {
                        reject: "".concat(apiRoot, "/workflow/partner/reject-job/").concat(token),
                        accept: "".concat(apiRoot, "/workflow/partner/accept-job/").concat(token),
                    },
                    getDriverList: "".concat(apiRoot, "/workflow/partner/accept-job/").concat(token, "/drivers"),
                    invoice: {
                        upload: "".concat(apiRoot, "/admin/documents/").concat(token),
                    },
                }),
            }); },
        },
        location: {
            retrieve: {
                code: "".concat(apiRoot, "/admin/utilities/geolocation/retrieve-code"),
                address: "".concat(apiRoot, "/admin/utilities/geolocation/retrieve-door-address"),
                billing: "".concat(apiRoot, "/admin/utilities/geolocation/retrieve-billing-address"),
            },
        },
    });
};
exports.getApiRoutes = getApiRoutes;
