"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressString = exports.getAddressByCoords = exports.getLeafletLatLangLiteral = exports.extractDisplayLocationCoords = void 0;
const core_model_1 = require("@adac/core-model");
const common_1 = require("../networking/common");
function extractDisplayLocationCoords(object) {
    const coords = (0, core_model_1.extractLocationCoords)(object);
    return ((0, core_model_1.isLocationCoordsValid)(coords) && coords) || core_model_1.defaultLocationDisplayOnError;
}
exports.extractDisplayLocationCoords = extractDisplayLocationCoords;
const getLeafletLatLangLiteral = (coords) => ({ lat: coords.latitude || core_model_1.defaultLocationDisplayOnError.latitude, lng: coords.longitude || core_model_1.defaultLocationDisplayOnError.longitude });
exports.getLeafletLatLangLiteral = getLeafletLatLangLiteral;
const getAddressByCoords = (_a) => __awaiter(void 0, void 0, void 0, function* () {
    var { latitude, longitude } = _a, props = __rest(_a, ["latitude", "longitude"]);
    if (latitude && longitude) {
        const foundAddress = yield (0, common_1.request)((0, core_model_1.getApiRoutes)().location.retrieve.code, {
            method: 'POST',
            data: Object.assign({ latitude, longitude }, props),
            timeout: core_model_1.config.location().apiTimeout,
        });
        if (foundAddress && !(0, core_model_1.isLocationError)(foundAddress)) {
            return foundAddress;
        }
    }
    return undefined;
});
exports.getAddressByCoords = getAddressByCoords;
function getAddressString({ zip, place, street, houseNumber, }) {
    const hasAddress = zip !== '' || place !== '' || street !== '' || houseNumber !== '';
    const houseNumberString = houseNumber.length > 0 ? ` ${houseNumber}` : '';
    return hasAddress ? `${street}${houseNumberString}, ${zip} ${place}` : (0, core_model_1.__)('No address provided');
}
exports.getAddressString = getAddressString;
