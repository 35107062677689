"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginWithPassword = exports.confirmToken = exports.isConfirmTokenResponeBody = exports.requestMFATokenKey = void 0;
const core_model_1 = require("@adac/core-model");
const common_1 = require("./common");
const requestMFATokenKey = (url) => (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, common_1.request)(url, {
        method: 'POST', data: { username, password },
    });
    if (data.tokenKey)
        return data.tokenKey;
    throw new Error('No tokenKey returned');
});
exports.requestMFATokenKey = requestMFATokenKey;
function isConfirmTokenResponeBody(body) {
    if (body !== 'OK' && (body === null || body === void 0 ? void 0 : body.token))
        return true;
    return false;
}
exports.isConfirmTokenResponeBody = isConfirmTokenResponeBody;
const confirmToken = (tokenKey, tokenValue) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield (0, common_1.request)((0, core_model_1.getApiRoutes)().auth.mfa.confirm, {
            method: 'POST',
            data: { tokenKey, tokenValue },
        });
        if (isConfirmTokenResponeBody(data))
            return data.token;
        return null;
    }
    catch (err) {
        throw new Error(err);
    }
});
exports.confirmToken = confirmToken;
const loginWithPassword = (url, username, password) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield (0, common_1.request)(url, {
        method: 'POST',
        data: { username, password },
    });
    const token = data.token;
    if (token)
        return token;
    throw new Error('No token returned');
});
exports.loginWithPassword = loginWithPassword;
