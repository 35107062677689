"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
var location_1 = require("./location");
var datetime_1 = require("./datetime");
var sentry_1 = require("./sentry");
var invoice_1 = require("./invoice");
__exportStar(require("./prices"), exports);
__exportStar(require("./location"), exports);
__exportStar(require("./sms"), exports);
__exportStar(require("./cron"), exports);
__exportStar(require("./titles"), exports);
__exportStar(require("./cancellationReasons"), exports);
__exportStar(require("./tacItems"), exports);
exports.config = {
    sentry: sentry_1.default,
    invoiceModule: invoice_1.default,
    location: location_1.default,
    date: datetime_1.date,
    time: datetime_1.time,
    iso: datetime_1.iso,
    yearMonth: datetime_1.yearMonth,
    dateFormat: datetime_1.dateFormat,
    dateEditFormat: datetime_1.dateEditFormat,
    timestampFormat: datetime_1.timestampFormat,
    timestampEditFormat: datetime_1.timestampEditFormat,
};
