"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadInvoice = void 0;
const core_model_1 = require("@adac/core-model");
const common_1 = require("./common");
const uploadInvoice = (jwtToken, caseToken, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append('fileContent', invoice);
    return (0, common_1.request)((0, core_model_1.getApiRoutes)().partner.case(caseToken).action.invoice.upload, {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${jwtToken}`,
        },
        data: formData,
    });
});
exports.uploadInvoice = uploadInvoice;
