"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.disguiseDriverInfo = exports.disguisedFamilyName = exports.disguisedFirstName = exports.getValueBasedOnCompanyType = exports.isDriverBoss = exports.isCompanyActive = exports.allowedUserSignInSettingRegexp = exports.allPartnerMessageTypeRegexp = exports.activePartnerMessageTypeRegexp = exports.disabledMessageTypeRegexp = exports.allMessageTypeRegexp = exports.realMessageTypeRegexp = exports.getPasswordRegex = exports.contactManagerIdPattern = exports.contactManagerIdCorePattern = exports.extractContactManagerId = exports.getCompanyId = void 0;
var Company_1 = require("../types/Company");
var UserRoles_1 = require("../types/UserRoles");
var getCompanyId = function (company) { return "".concat(company.id, "/paris-AMP-SD-").concat(company.type.toUpperCase(), "-").concat(company.contactManagerId); };
exports.getCompanyId = getCompanyId;
var extractContactManagerId = function (commissioningReference) { var _a; return (_a = commissioningReference.match(/(\d{9,10})\w*$/)) === null || _a === void 0 ? void 0 : _a[0]; };
exports.extractContactManagerId = extractContactManagerId;
exports.contactManagerIdCorePattern = '(\\d{9,10})';
exports.contactManagerIdPattern = new RegExp("^".concat(exports.contactManagerIdCorePattern, "$"));
var getPasswordRegex = function () { return new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_]).{14,}$'); };
exports.getPasswordRegex = getPasswordRegex;
exports.realMessageTypeRegexp = /(sms)|(email)|(sms_vonage)/;
exports.allMessageTypeRegexp = /(sms)|(email)|(off)|(sms_vonage)/;
exports.disabledMessageTypeRegexp = /(off)/;
exports.activePartnerMessageTypeRegexp = /(sms_vonage)|(email)/;
exports.allPartnerMessageTypeRegexp = /(email)|(off)|(sms_vonage)/;
exports.allowedUserSignInSettingRegexp = /(sms)|(email)/;
var isCompanyActive = function (company) {
    var today = new Date(Date.now());
    var notYetActive = (company.activeFrom && new Date(company.activeFrom) > today);
    var expired = (company.activeTo && today > new Date(company.activeTo));
    return !(notYetActive || expired);
};
exports.isCompanyActive = isCompanyActive;
var isDriverBoss = function (driverParam) {
    if (!driverParam)
        return false;
    var user = driverParam.user;
    if (!user)
        return false;
    var roles = user.roles.map(function (role) { return role.name; });
    return roles.includes(UserRoles_1.UserRole.COMPANY);
};
exports.isDriverBoss = isDriverBoss;
var getValueBasedOnCompanyType = function (type, value, disguise) {
    if (disguise === void 0) { disguise = null; }
    switch (type) {
        case Company_1.CompanyTypes.ADAC:
            return disguise;
        default:
            return value;
    }
};
exports.getValueBasedOnCompanyType = getValueBasedOnCompanyType;
exports.disguisedFirstName = 'ADAC';
exports.disguisedFamilyName = 'Fahrer';
var disguiseDriverInfo = function (driverInfo, companyType) {
    if (!driverInfo || !driverInfo.driver)
        return driverInfo;
    var _a = driverInfo.driver, firstName = _a.firstName, familyName = _a.familyName, photo = _a.photo;
    return __assign(__assign({}, driverInfo), { driver: __assign(__assign({}, driverInfo.driver), { firstName: (0, exports.getValueBasedOnCompanyType)(companyType, firstName, exports.disguisedFirstName) || '', familyName: (0, exports.getValueBasedOnCompanyType)(companyType, familyName, exports.disguisedFamilyName) || '', photo: (0, exports.getValueBasedOnCompanyType)(companyType, photo) }) });
};
exports.disguiseDriverInfo = disguiseDriverInfo;
