import React from 'react';
import styled from 'styled-components';

import { SubPage, Title, Text } from '@adac/core-view';
import { __ } from '@adac/core-model';


const ThankYouTitle = styled(Title)`
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 8px;
`;


export default () => (
  <SubPage>
    <ThankYouTitle center>{__('thankYouForYourReview')}</ThankYouTitle>
    <Text center>{__('yourHelpImprovesOurService')}</Text>
  </SubPage>
);
