"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composeFuncs = void 0;
var composeFuncs = function () {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function (initialValue) { return fns.reduceRight(function (prevValue, fn) { return fn(prevValue); }, initialValue); };
};
exports.composeFuncs = composeFuncs;
