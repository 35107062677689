"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getListWithHeaders = exports.request = void 0;
var axios_1 = require("axios");
var NetworkError_1 = require("../types/NetworkError");
var errors_1 = require("../errors");
axios_1.default.defaults.headers.common['snd-app-version'] = "".concat(process.env.REACT_APP_VERSION || process.env.VERSION);
function sleepOnAttempt(attempt, unit) {
    if (unit === void 0) { unit = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, new Promise(function (resolve) { return setTimeout(resolve, unit * attempt * 1.3); })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    });
}
function request(url, _a) {
    var createCustomError = _a.createCustomError, _b = _a.method, method = _b === void 0 ? 'GET' : _b, _c = _a.timeout, timeout = _c === void 0 ? 0 : _c, _d = _a.retryAttempts, retryAttempts = _d === void 0 ? 0 : _d, headers = _a.headers, data = _a.data;
    return __awaiter(this, void 0, void 0, function () {
        var attempt, response, error_1;
        var _this = this;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    attempt = 0;
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    if (retryAttempts) {
                        axios_1.default.interceptors.response.use(function (value) { return value; }, function (error) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (!(error.config && ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === errors_1.HTTP_TOO_MANY_REQUESTS && attempt < retryAttempts)) return [3, 2];
                                        console.log('retry', attempt, 'on status', (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status, 'at', new Date());
                                        attempt += 1;
                                        return [4, sleepOnAttempt(attempt)];
                                    case 1:
                                        _c.sent();
                                        return [2, axios_1.default.request(error.config)];
                                    case 2: return [2, Promise.reject(error)];
                                }
                            });
                        }); });
                    }
                    return [4, axios_1.default.request({
                            url: url,
                            method: method,
                            headers: headers,
                            data: data,
                            timeout: timeout,
                        })];
                case 2:
                    response = _e.sent();
                    return [2, response.data];
                case 3:
                    error_1 = _e.sent();
                    throw createCustomError(error_1, url, method);
                case 4: return [2];
            }
        });
    });
}
exports.request = request;
function getListWithHeaders(url, token) {
    return __awaiter(this, void 0, void 0, function () {
        var response, resources, contentRange, total, error_2, newError;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, axios_1.default.get(url, {
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
                case 1:
                    response = _a.sent();
                    resources = response.data;
                    if (resources.length < 1)
                        return [2, { data: resources, total: resources.length }];
                    contentRange = response.headers['content-range'];
                    if (!contentRange) {
                        throw new Error('Missing content-range header');
                    }
                    total = parseInt(contentRange.split('/')[1], 10);
                    if (!total || isNaN(total)) {
                        throw new Error('Invalid content-range header');
                    }
                    return [2, { data: resources, total: total }];
                case 2:
                    error_2 = _a.sent();
                    newError = new NetworkError_1.NetworkError(error_2.response);
                    newError.originalError = error_2;
                    throw (newError);
                case 3: return [2];
            }
        });
    });
}
exports.getListWithHeaders = getListWithHeaders;
