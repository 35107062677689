"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrubEvent = exports.removeAuthHeader = exports.limitBreadCrumbs = void 0;
var compose_1 = require("./compose");
var scrub_1 = require("./scrub");
var BREADCRUMB_LIMIT = 64;
var filterBreadCrumbs = function (crumb) {
    if (crumb.data) {
        var newCrumb = __assign({}, crumb);
        var newData_1 = __assign({}, newCrumb.data);
        Object.keys(newData_1).forEach(function (key) {
            var value = newData_1[key];
            if (typeof value === 'string' && value.length > BREADCRUMB_LIMIT) {
                newData_1[key] = "".concat(value.substring(0, BREADCRUMB_LIMIT), "...");
            }
        });
        newCrumb.data = newData_1;
        return newCrumb;
    }
    return crumb;
};
var limitBreadCrumbs = function (event) {
    var _a;
    var breadcrumbs = (_a = event.breadcrumbs) !== null && _a !== void 0 ? _a : [];
    var newBreadcrumbs = breadcrumbs.filter(filterBreadCrumbs);
    var newEvent = event;
    newEvent.breadcrumbs = newBreadcrumbs;
    return newEvent;
};
exports.limitBreadCrumbs = limitBreadCrumbs;
var removeAuthHeader = function (event) {
    var _a;
    var newEvent = event;
    if ((_a = newEvent.request) === null || _a === void 0 ? void 0 : _a.headers) {
        delete newEvent.request.headers.Authorization;
    }
    return newEvent;
};
exports.removeAuthHeader = removeAuthHeader;
var keysToScrub = [
    'email',
    'username',
    'password',
    'customerEmail',
    'customerFirstName',
    'customerLastName',
    'customerFamilyName',
    'customerPhone',
    'customerAddressZip',
    'customerAddressCity',
    'customerAddressStreet',
    'customerAddressNumber',
    'title',
    'longitude',
    'latitude',
    'billingNameFirst',
    'billingAddressZip',
    'billingAddressCity',
    'billingVatNumber',
    'companyAddressZip',
    'companyAddressCity',
    'companyAddressStreet',
    'companyAddressNumber',
    'firstName',
    'familyName',
    'privatePhone',
    'name',
    'phone',
];
exports.scrubEvent = (0, compose_1.composeFuncs)(exports.limitBreadCrumbs, exports.removeAuthHeader, function (event) { return (0, scrub_1.scrubObject)(event, keysToScrub); });
