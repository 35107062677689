"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrubObject = void 0;
function scrubObject(obj, keysToScrub) {
    function scrub(obj) {
        if (Array.isArray(obj)) {
            return obj.map(function (item) { return scrub(item); });
        }
        if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce(function (acc, key) {
                if (keysToScrub.includes(key)) {
                    acc[key] = '****';
                }
                else {
                    acc[key] = scrub(obj[key]);
                }
                return acc;
            }, {});
        }
        if (typeof obj === 'string') {
            try {
                var parsedObj = JSON.parse(obj);
                if (parsedObj !== null && typeof parsedObj === 'object') {
                    return JSON.stringify(scrub(parsedObj));
                }
            }
            catch (e) {
            }
        }
        return obj;
    }
    return scrub(obj);
}
exports.scrubObject = scrubObject;
