"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTP_ERROR_CODE_SCOPE_MAX = exports.HTTP_GATEWAY_TIMEOUT = exports.HTTP_SERVICE_UNAVAILABLE = exports.HTTP_INTERNAL_SERVER_ERROR = exports.HTTP_TOO_MANY_REQUESTS = exports.HTTP_PRECONDITION_FAILED = exports.HTTP_VALIDATION_ERROR = exports.HTTP_NOT_FOUND = exports.HTTP_FORBIDDEN = exports.HTTP_UNAUTHORIZED = exports.HTTP_BAD_REQUEST = exports.HTTP_CREATED = exports.HTTP_OK = void 0;
exports.HTTP_OK = 200;
exports.HTTP_CREATED = 201;
exports.HTTP_BAD_REQUEST = 400;
exports.HTTP_UNAUTHORIZED = 401;
exports.HTTP_FORBIDDEN = 403;
exports.HTTP_NOT_FOUND = 404;
exports.HTTP_VALIDATION_ERROR = 422;
exports.HTTP_PRECONDITION_FAILED = 412;
exports.HTTP_TOO_MANY_REQUESTS = 429;
exports.HTTP_INTERNAL_SERVER_ERROR = 500;
exports.HTTP_SERVICE_UNAVAILABLE = 503;
exports.HTTP_GATEWAY_TIMEOUT = 504;
exports.HTTP_ERROR_CODE_SCOPE_MAX = 600;
