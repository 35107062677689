"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentGeoLocation = void 0;
const react_1 = require("react");
const core_model_1 = require("@adac/core-model");
const positionOptions = {
    enableHighAccuracy: core_model_1.config.location().enableHighAccuracy,
    maximumAge: core_model_1.config.location().browserMaxAge,
    timeout: core_model_1.config.location().browserTimeout,
};
function useCurrentGeoLocation(onSettled) {
    const [position, setPosition] = (0, react_1.useState)(null);
    const [error, setError] = (0, react_1.useState)(undefined);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const { navigator } = window;
    const onSuccess = (0, react_1.useCallback)((pos) => __awaiter(this, void 0, void 0, function* () {
        const hasSameCoordinates = pos.coords.latitude === (position === null || position === void 0 ? void 0 : position.coords.latitude) && pos.coords.longitude === (position === null || position === void 0 ? void 0 : position.coords.longitude);
        if (hasSameCoordinates) {
            setError({ code: 5, message: (0, core_model_1.__)('Your geolocation position is the same as previously') });
        }
        else {
            setError(undefined);
            setPosition(pos);
            yield onSettled({ position: pos, isLoading });
        }
        setIsLoading(false);
    }), [isLoading, onSettled, position]);
    const onError = (0, react_1.useCallback)((err) => __awaiter(this, void 0, void 0, function* () {
        console.error('Geolocation error', err);
        setError(err);
        yield onSettled({ error: err, position: null, isLoading });
        setIsLoading(false);
    }), [isLoading, onSettled]);
    const locate = (0, react_1.useCallback)(() => {
        if (isLoading)
            return;
        setIsLoading(true);
        if (!navigator.geolocation) {
            setError({ code: 4, message: 'Geolocation wird von diesem Browser nicht unterstützt' });
        }
        else {
            navigator.geolocation.getCurrentPosition(onSuccess, onError, positionOptions);
        }
    }, [isLoading, navigator.geolocation, onError, onSuccess]);
    return {
        position, error, isLoading, locate,
    };
}
exports.useCurrentGeoLocation = useCurrentGeoLocation;
