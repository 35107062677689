"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sentry = function (envVarSuffix) {
    var _a = process.env, sentryLevels = _a.REACT_APP_SENTRY_LEVELS, NODE_ENV = _a.NODE_ENV;
    var parsedLevels;
    try {
        parsedLevels = JSON.parse(sentryLevels || '[]');
    }
    catch (err) {
        console.log(err);
        console.log('Sentry Config: REACT_APP_SENTRY_LEVELS should be a valid JSON array');
        parsedLevels = [];
    }
    return {
        sentryUrl: process.env["REACT_APP_SENTRY_URL_".concat(envVarSuffix)],
        debug: !!(process.env["REACT_APP_SENTRY_URL_".concat(envVarSuffix)] && NODE_ENV === 'development'),
        sentryLevels: parsedLevels,
    };
};
exports.default = sentry;
