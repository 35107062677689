"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationAPIRoutes = void 0;
var getConfig = function () {
    var _a = process.env, _b = _a.GEOCODE_SERVICE_BASE_URL, baseUrl = _b === void 0 ? 'https://geocode.search.hereapi.com/v1' : _b, apiKey = _a.GEOCODE_SERVICE_API_KEY, minConfidence = _a.GEOCODE_SERVICE_MIN_CONFIDENCE_DISPLAY, suggestionLimit = _a.GEOCODE_SERVICE_SUGGESTION_LIMIT, acceptDoorConfidence = _a.GEOCODE_SERVICE_MIN_CONFIDENCE_ACCEPT_DOOR, acceptBilingConfidence = _a.GEOCODE_SERVICE_MIN_CONFIDENCE_ACCEPT_BILLING, configuredTimeout = _a.GEOCODE_SERVICE_TIMEOUT, testZipCode = _a.GEOCODE_SERVICE_TEST_ZIP, browserMaxAge = _a.REACT_APP_GEOCODE_SERVICE_MAX_AGE, browserTimeout = _a.REACT_APP_GEOCODE_SERVICE_TIMEOUT, browserEnableHighAccuracy = _a.REACT_APP_GEOCODE_SERVICE_ENABLE_HIGH_ACCURACY, minBillingZipLen = _a.REACT_APP_ADDRESSFORM_MINLEN_ZIP_BILLING, minPlaceLen = _a.REACT_APP_ADDRESSFORM_MINLEN_PLACE, minStreetLen = _a.REACT_APP_ADDRESSFORM_MINLEN_STREET;
    return ({
        baseUrl: baseUrl,
        apiKey: apiKey,
        apiTimeout: Number(configuredTimeout) || 3000,
        browserTimeout: Number(browserTimeout) || 5000,
        browserMaxAge: Number(browserMaxAge) || 0,
        enableHighAccuracy: Boolean(browserEnableHighAccuracy) || true,
        minConfidence: Number(minConfidence) || 0.4,
        suggestionLimit: Number(suggestionLimit) || 4,
        acceptDoorConfidence: Number(acceptDoorConfidence) || 0.96,
        acceptBilingConfidence: Number(acceptBilingConfidence) || 0.80,
        testZipCode: Number(testZipCode) || 99999,
        minZipLen: Number(minBillingZipLen) || 4,
        minPlaceLen: Number(minPlaceLen) || 4,
        minStreetLen: Number(minStreetLen) || 3,
        retryAttempts: 3,
    });
};
var getLocationAPIRoutes = function (validateConfig) {
    if (validateConfig === void 0) { validateConfig = true; }
    var _a = getConfig(), apiKey = _a.apiKey, baseUrl = _a.baseUrl;
    if (validateConfig && !(baseUrl && apiKey))
        throw new Error('GEOCODE_SERVICE_API_KEY has to be defined!');
    return Object.freeze({
        retrieve: {
            address: function (_a) {
                var latitude = _a.latitude, longitude = _a.longitude;
                return "".concat(baseUrl, "/revgeocode?at=").concat(encodeURI("".concat(latitude, ",").concat(longitude)), "&apiKey=").concat(apiKey);
            },
            coords: function (_a) {
                var zip = _a.zip, place = _a.place, street = _a.street, houseNumber = _a.houseNumber;
                return "".concat(baseUrl, "/geocode?q=").concat(encodeURI("".concat(zip, ",").concat(place, ",").concat(street, ",").concat(houseNumber)), "&apiKey=").concat(apiKey);
            },
            suggestions: function (_a) {
                var zip = _a.zip, place = _a.place, street = _a.street, houseNumber = _a.houseNumber;
                return "".concat(baseUrl, "/geocode?q=").concat(encodeURI("".concat(zip, ",").concat(place, ",").concat(street, ",").concat(houseNumber)), "&apiKey=").concat(apiKey);
            },
        },
    });
};
exports.getLocationAPIRoutes = getLocationAPIRoutes;
exports.default = getConfig;
