import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Page, View, ListAllIcons } from '@adac/core-view';

import StoreContext from '../../stores';

export default function MainRouterContainer(): JSX.Element {
  const stores = useContext(StoreContext);

  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <Page border="cyan" overflow="scroll" fullHeight>
        <View title="true">DEVELOPER TOOLS</View>
        <Switch>
          <Route path="/dev/list/icons" component={ListAllIcons} />
        </Switch>
      </Page>
    </ThemeProvider>
  );
}
